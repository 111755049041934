$yellow: rgb(248, 211, 99);
$green: rgb(126, 117, 65);
$pink: rgb(209, 143, 156);
$beige: rgb(196, 174, 155);
$lightgreen: rgb(164, 166, 140);
$darkred: rgb(125, 46, 28);
$brightred: rgb(236, 69, 55);
$offwhite: rgb(225, 224, 224);

$rickyOrangeRed: rgb(255, 92, 59);

$originalRed: #C74837;


* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  background-color: $originalRed;
  color: $offwhite;
}

.background-color {
  position: fixed;
  // background-color: blue;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.home-page,
.about-page,
.book-page {
  background-color: $originalRed;
}

// .home-page {
//   background-color: $originalRed;
//   animation: fade-in 3s;
// }

// .about-page {
//   background-color: $green;
//   animation: fade-in 1s;
// }

// .book-page {
//   background-color: $pink;
//   animation: fade-in 1s;
// }



#root {
  height: 100%;
}

main {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.logo-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.logo {
  width: 600px;
}

//* To prevent resizing on load:
// .img-wrapper {
//   width: 600px;
//   height: 200px;
// }

.holding-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

// .image-wrapper {
//   width: 600px;
//   height: 200px;
// }

.logoOriginal {
  width: 300px;
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

ul {
  margin: 0;
  padding: 30px 7px;
  width: 100%;
  max-width: 485px;
  position: absolute;
}

body {
  font-family: NewYork;
  text-transform: uppercase;
  font-weight: lighter;
}

nav {
  display: flex;
  width: 100%;
  justify-content: center;
}

// //* Rounded button option:
.nav-item a {
  color: inherit;
  padding: 11px 15px;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
  box-shadow: 0 0 7px 1px white;
  border-radius: 139px;
  // font-size: 16px !important;
  letter-spacing: 1px;
}

.nav-item a:hover {
  background-color: white;
  color: #C74837;
}


//* Adjust nav item-size
// .nav-item a {
//   font-size: 20px;
// }


ul,
li {
  display: flex;
}

ul {
  justify-content: space-between;
}



a {
  color: inherit;
  text-decoration: none;
  display: inline-block;
  // transition: all .5s ease-in-out;
}

// a:hover {
//   transform: scale(1.06);
// }

.info {
  display: flex;
  align-items: center;
  flex-direction: column;
}

h4 {
  text-transform: none;
  margin: 18px 10px 10px;
  font-size: 18px;
}

.scroll-text {
  margin: 0;
  white-space: nowrap;
  width: min-content;
}

.scroll-banner-top {
  position: fixed;
  height: 45px;
}

.scroll-banner-bottom {
  position: fixed;
  bottom: 0;
}

// .scroll-banner-bottom {
//   position: fixed;
//   // bottom: 0;
// }

.content {
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.25s ease-in-out;
  padding-bottom: 24.5px;
}



.scroll-banner-top,
.scroll-banner-bottom {
  // display: flex;
  width: 100%;
  font-weight: bold;
  // height: 45px;
  overflow: hidden;
  z-index: 1;
  cursor: default;
  background-color: #C74837;
  box-shadow: 0 0 35px 15px #C74837;

  .scroll-content {
    align-items: center;
    white-space: nowrap;
    text-transform: uppercase;
    display: flex;
    letter-spacing: 1px;
    height: 45px;


    &>.scroll-container-top {
      display: flex;
      animation: scrollText 223s infinite linear;
    }

    &>.scroll-container-bottom {
      display: flex;
      animation: scrollTextBottom 223s infinite linear;
    }

    p {
      animation: fade-in 3s;
      font-size: 20px;
    }
  }
}


@keyframes scrollText {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-50%);
  }
}

@keyframes scrollTextBottom {
  from {
    transform: translateX(-50%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes fade-out {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

// .scroll-banner-top {
//   color: black;
// }

// // .scroll-banner-bottom {
// //   position: fixed;
// // }

.exiting {
  opacity: 0;
  animation: fade-out .5s ease-out;
}

.content {
  width: 100%;
  height: 100%;
  animation: fade-in .5s ease-in;
  overflow: scroll;
}

.about-container {
  // height: 100%;
  width: 100%;
  // overflow: auto;
}

.contact-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 250px;
}

.image-wrapper {
  // height: 225px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-bottom: 28px;
  // margin-top: 28px;
  border-radius: 33px;
}

.book-option .image-wrapper {
  margin: 28px;
}

.text-wrapper.about-text {
  width: 335px;
  margin-left: 30px;
}


.text-wrapper {
  text-transform: none;
  white-space: pre-line;
  font-family: Helvetica;
  width: 260px;
  font-weight: normal;
  line-height: 1.3;



  //* Uncomment the below to test font:
  // font-family: NewYork;
  // font-family: Fixture;

  // font-weight: lighter;
  // font-weight: bold;
  text-align: justify;
  font-size: 14px;
  display: flex;
  // flex-direction: column;
  justify-content: center;
}

.text-wrapper p {
  text-align: center;
}

.text-wrapper p span {
  font-weight: bold;
  font-size: 17px;
}

.about-image {
  width: 400px;
  border-radius: 33px;
}

.about-profile {
  display: flex;
  align-items: center;
}

.book-image {
  width: 260px;
  height: 260px;
  object-fit: cover;
  border-radius: 33px;
}

.book-here {
  border: 1px solid white;
  font-weight: 600;
  padding: 7px;
  border-radius: 10px;
  opacity: 80%;
}

.email-contact,
.insta-contact,
.whatsapp-contact {
  width: min-content;
  margin: 11px;
}

.email-contact a,
.insta-contact a,
.whatsapp-contact a {
  display: flex;
  margin: 16px 0;
}

.email-contact p,
.insta-contact p,
.whatsapp-contact p {
  width: min-content;
  margin-left: 7px;
  height: min-content;
  padding-top: 3px;
}

p {
  margin: 0;
}

// .contact-content {
//   font-family: Fixture;
//   font-family: Helvetica;
//   font-weight: lighter;
//   text-transform: none;
//   margin: 30px 0;
// }

.back-button {
  height: 70px;
  /* padding-left: 5px; */
  left: 10px;
  position: absolute;
}

.back-image {
  width: 35px;
}

.back-button p {
  font-family: Helvetica;
  font-size: 13px;
  text-transform: none;
  font-weight: lighter;
  margin-top: -5px;
}

.book-section {
  display: flex;
}

.book-option {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-icon {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 690px) {
  .book-section {
    display: block;
    margin: 20px;
    padding-top: 20px;
  }

  .book-container,
  .about-container {
    // height: 100vh;
    width: 100%;
    // overflow: auto;
  }

  .book-option {
    margin-bottom: 30px;
  }

  .about-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }

  .about-image {
    width: 240px;
  }

  .text-wrapper.about-text {
    width: 250px;
    margin-left: 0;
    margin-top: 30px;
  }

  .contact-container {
    height: 200px;
  }

  .logo {
    width: 350px;
  }

  // .img-wrapper {
  //   width: 350px;
  //   height: 110px;
  // }

  ul {
    padding: 15px 14px;
    max-width: 385px;
  }

  .nav-item a {
    font-size: 12px;
    padding: 10px;
  }
}

@media (max-width: 961px) {
  .about-image {
    width: 300px;
  }
}